<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
        <el-table-column prop="logo" width="90" label="图片" >
          <template #default="scope">
            <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
              <el-image
                  v-if="scope.row.pic_url"
                  style="width: 50px; height: 50px"
                  :src="scope.row.pic_url"
                  :preview-src-list="[scope.row.pic_url]">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="x" label="距离X坐标位置" >
        </el-table-column>
        <el-table-column prop="y" label="距离Y坐标位置" >
        </el-table-column>
        <el-table-column prop="qr_size" label="二维码宽高" >
        </el-table-column>
        <el-table-column prop="is_emp" label="是否员工海报" >
          <template #default="scope">
            <el-tag v-if="scope.row.is_emp==1">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="110">
          <template #header>
            <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>

      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="70%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
          <el-scrollbar height="calc(100vh - 160px)" style="padding:0 15px;height:calc(100% - 30px) ">
            <div style="display: flex;width:843px;">
              <div style="width: 540px;height:960px;position:relative;">
                <el-image
                    v-if="ruleForm.pic_url"
                    style="width:100%; height:100%"
                    :src="ruleForm.pic_url">
                </el-image>
                <div :style="'position:absolute;outline:4px solid #ffaf64 ;width:'+ruleForm.qr_size+'px;height:'+ ruleForm.qr_size+'px;top:'+ruleForm.y+'px;left:'+ ruleForm.x+'px'">
                </div>
              </div>
              <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="120px"
                  class="demo-ruleForm">
                <el-form-item label="图片" prop="pic_url">
                  <div style="height: 100px">
                    <el-upload
                        style=" width: 180px;height: 180px"
                        class="avatar-uploader"
                        :action="UpURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :http-request="httpRequest"
                        :before-upload="beforeAvatarUpload">
                      <img v-if="ruleForm.pic_url" :src="ruleForm.pic_url" class="avatar" />
                      <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </div>
                  <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸宽1080 X 高1920</p>
                </el-form-item>
                <el-form-item label="距离X坐标位置" prop="x">
                  <el-input-number v-model="ruleForm.x" controls-position="right" :min="0" ></el-input-number>
                </el-form-item>
                <el-form-item label="距离Y坐标位置" prop="x">
                  <el-input-number v-model="ruleForm.y" controls-position="right" :min="0" ></el-input-number>
                </el-form-item>
                <el-form-item label="二维码大小" prop="qr_size">
                  <el-input-number v-model="ruleForm.qr_size" controls-position="right" :min="0" ></el-input-number>
                </el-form-item>
                <el-form-item label="是否员工海报" prop="qr_size">
                  <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
                    <el-switch
                        style="display: block"
                        v-model="ruleForm.is_emp"
                        active-text="是"
                        inactive-text="否"
                        :active-value="1"
                        :inactive-value="0">
                    </el-switch>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
                  <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
                </el-form-item>
              </el-form>
            </div>

          </el-scrollbar>

    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getUQIMGurl,getIMG} from "@/api/common"//图片上传接口
import * as axios from "@/api/posterapi"//本页面接口
export default {
  name: 'poster',
  data(){
    return{
      UpURL:'',
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      logo:'',
      keywords:'',
      ruleForm:{
        pic_url:'',
        x:0,
        y:0,
        qr_size:0,
        is_emp:'',
      },
      rules:{
        pic_url: [
          { required: true, message: '请上传图片', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getposterData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm= {
        pic_url:'',
        x:0,
        y:0,
        qr_size:0,
        is_emp:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.pic_url=row.pic_url
      this.ruleForm.x=row.x
      this.ruleForm.y=row.y
      this.ruleForm.qr_size=row.qr_size
      this.ruleForm.is_emp=row.is_emp
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddposter(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditposter(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelposter({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    handleAvatarSuccess(res, file) {
      this.logo = URL.createObjectURL(file.raw)
    },

    //上传图片方法
    httpRequest(options) {
      this.login=true
      console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
      getUQIMGurl({bucket:'image',ext:'.'+src.substr(6)}).then((res)=>{
        console.log('获取URL',res)
        if(res.code==0){
          this.UpURL=res.data.upload_url
         let url=res.data.preview_url
          console.log(this.UpURL)
          getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
            resolve(res)
            this.ruleForm.pic_url=url
            this.login=false
          }).catch((err)=>{
            reject(err)
            this.login=false
          })

        }else {
          this.$message.error('获取上传地址失败！！')
          this.login=false
        }
      })

      })
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
                    file.type === "image/png" ||
                    file.type === "image/jpg"||
                    file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
        // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
          // && isLt2M
    },
  }
}
</script>
<style lang="less" >
.tab{
  margin: 5px 0 ;
  padding: 10px 20px;
  border: 1px solid #e8e8e8;
}

.avatar-uploader .el-upload {
  width: 98px!important;
  height: 98px!important;
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{
  width: 98px!important;
  height: 98px!important;
}
.el-checkbox{
  height: 32px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px!important;
  height: 98px!important;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px!important;
  height: 98px!important;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
.suo .el-card__body{
  padding: 0!important;
}
.meng:hover .meng_1{
  display: block!important;
}
</style>