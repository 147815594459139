//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//获取列表接口
export function getposterData(obj,url) {
    return request({
        url: '/api/admin/poster',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增品牌接口
export function postaddposter(obj,url) {
    return request({
        url: '/api/admin/poster/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑品牌接口
export function posteditposter(obj,url) {
    return request({
        url: '/api/admin/poster/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除品牌接口
export function postdelposter(obj,url) {
    return request({
        url: '/api/admin/poster/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

